import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useFetchEvents, categorizeEvents } from './eventUtils';
import './main.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Clock from './Clock';


function EventsDisplay() {
    const { events, loading, error } = useFetchEvents();
    const [loggedDates, setLoggedDates] = useState({});
    const [fadeStates, setFadeStates] = useState({});
    const footerRef = useRef(null);
    
    const [eventsNow, setEventsNow] = useState([]);
    const nowEventRefs = useRef({});
    const noEventsNowRef = useRef(null);

    const [eventsLater, setEventsLater] = useState([]);
    const laterEventRefs = useRef({});
    const noEventsLaterRef = useRef(null);

    const [upcoming, setUpcoming] = useState({});
    const upcomingEventRefs = useRef({});

    
    
    

    const getFadeClassName = useCallback((date, isTitle = false, isLaterEventTitle = false, isNowEventTitle = false) => {
        const key = isTitle ? Object.keys(upcoming)[0] :
            isLaterEventTitle ? (eventsLater.length === 0 ? 'noEventsLater' : eventsLater[0]?.id || 'visible') :
                isNowEventTitle ? (eventsNow.length === 0 ? 'noEventsNow' : eventsNow[0]?.id || 'visible') :
                    date;


        const fadeState = fadeStates[key];

        switch (fadeState) {
            case 'hidden': return 'hiddenEvent';
            case 'fadeIn': return 'fadeInEvent';
            default: return '';
        }
    }, [fadeStates, upcoming, eventsLater, eventsNow]);



    const updateFadeState = useCallback((date, currentFadeState) => {
        if (currentFadeState === 'fading' || currentFadeState === 'fadeIn') {
            setTimeout(() => {
                setFadeStates(prev => ({
                    ...prev,
                    [date]: currentFadeState === 'fading' ? 'hidden' : 'visible'
                }));
            }, 500);
        }
    }, [setFadeStates]);


    const updateVisibility = useCallback(() => {
        const footerTop = footerRef.current ? footerRef.current.getBoundingClientRect().top : window.innerHeight;

        const updateElementVisibility = (ref, id) => {
            const elem = ref.current;
            if (elem) {
                const { bottom } = elem.getBoundingClientRect();
                const isCut = bottom > footerTop;
                const wasCut = loggedDates[id];
                const fadeState = fadeStates[id] || 'visible';

                if (wasCut !== isCut) {
                    console.log(`${id} is ${isCut ? '' : 'no longer '}being cut`);
                    setLoggedDates(prevDates => ({ ...prevDates, [id]: isCut }));
                    setFadeStates(prev => ({ ...prev, [id]: isCut ? 'fading' : 'fadeIn' }));
                }

                if ((isCut && fadeState !== 'hidden') || (!isCut && fadeState !== 'visible')) {
                    updateFadeState(id, fadeState);
                }
            }
        };

        [
            ...Object.entries(upcomingEventRefs.current), 
            ...Object.entries(laterEventRefs.current), 
            ...Object.entries(nowEventRefs.current), 
            ['noEventsNow', noEventsNowRef],
            ['noEventsLater', noEventsLaterRef]
        ]
            .forEach(([id, ref]) => updateElementVisibility(ref, id));

    }, [loggedDates, fadeStates, upcomingEventRefs, laterEventRefs, nowEventRefs, updateFadeState, footerRef]);



    useEffect(() => {
        function handleCategorizeEvents() {
            const categorized = categorizeEvents(events);
            setEventsNow(categorized.eventsNow);
            setEventsLater(categorized.eventsLater);
            setUpcoming(categorized.upcoming);
        }

        const intervalId = setInterval(handleCategorizeEvents, 60000);
        handleCategorizeEvents();

        const observer = new MutationObserver(updateVisibility);
        const container = document.querySelector('.eventsList');

        if (container) {
            observer.observe(container, {
                childList: true,
                subtree: true,
            });
        }

        window.addEventListener('resize', updateVisibility);
        updateVisibility();

        return () => {
            clearInterval(intervalId);
            observer.disconnect();
            window.removeEventListener('resize', updateVisibility);
        };
    }, [events, updateVisibility]);


    if (error) {
        return <div className="error">Error loading events: {error.message}</div>;
    }

    if (loading) {
        return (<div className="loading">Loading events ...</div>);
    }

    const titleFadeClass = getFadeClassName(null, true);
    const laterTitleFadeClass = getFadeClassName(null, false, true);
    const nowTitleFadeClass = getFadeClassName(null, false, false, true);


    const Footer = () => (
        <footer ref={footerRef} className="footer">
            <a href="https://perris.masjid.events/" target="_blank" rel="noopener noreferrer">
                perris.masjid.events
            </a>
        </footer>
    );

    const isMobile = window.innerWidth <= 768;
    const timeoutDuration = isMobile ? 0 : 5000;

    return (
        <div>
            <div>
                <Clock />
                <div>
                    <h2 className={nowTitleFadeClass}>Happening Now</h2>
                    <div className="eventsList">
                        <TransitionGroup component="ul">
                            {eventsNow.map((event) => {
                                nowEventRefs.current[event.id] = nowEventRefs.current[event.id] || React.createRef();
                                return (
                                    <CSSTransition
                                        key={event.id}
                                        classNames="event"
                                        timeout={timeoutDuration}
                                        onEntered={updateVisibility}
                                        onExited={updateVisibility}
                                    >
                                        <li className={`eventContainer ${getFadeClassName(event.id)}`} ref={nowEventRefs.current[event.id]}>
                                            <a href={`https://www.google.com/search?q=${encodeURIComponent(event.eventName + ' Islamic Center of Perris')}`} target="_blank" rel="noopener noreferrer" className="eventDetailsLink">
                                                <div className="eventDetails">
                                                    <strong>{event.eventName}</strong><br />
                                                    Date: {event.eventDate}<br />
                                                    Location: {event.eventLocation}<br />
                                                    Recurrence: {event.eventRecurrence}<br />
                                                </div>
                                            </a>
                                            <div className="eventTime">
                                                {event.eventStartTime} - {event.eventEndTime}
                                            </div>
                                        </li>
                                    </CSSTransition>
                                );
                            })}
                            {eventsNow.length === 0 && (
                                <CSSTransition
                                    classNames="noevent"
                                    timeout={timeoutDuration}
                                    onEntered={updateVisibility}
                                    onExited={updateVisibility}
                                >
                                    <li className={`no-events ${getFadeClassName('noEventsNow')}`} ref={noEventsNowRef}>
                                        No events happening now.
                                    </li>
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                </div>

                <div>
                    <h2 className={laterTitleFadeClass}>Later Today</h2>
                    <div className="eventsList">
                        <TransitionGroup component="ul">
                            {eventsLater.map((event) => {
                                laterEventRefs.current[event.id] = laterEventRefs.current[event.id] || React.createRef();
                                return (
                                    <CSSTransition
                                        key={event.id}
                                        classNames="event"
                                        timeout={timeoutDuration}
                                        onEntered={updateVisibility}
                                        onExited={updateVisibility}
                                    >
                                        <li className={`eventContainer ${getFadeClassName(event.id)}`} ref={laterEventRefs.current[event.id]}>
                                            <a href={`https://www.google.com/search?q=${encodeURIComponent(event.eventName + ' Islamic Center of Perris')}`} target="_blank" rel="noopener noreferrer" className="eventDetailsLink">
                                                <div className="eventDetails">
                                                    <strong>{event.eventName}</strong><br />
                                                    Date: {event.eventDate}<br />
                                                    Location: {event.eventLocation}<br />
                                                    Recurrence: {event.eventRecurrence}<br />
                                                </div>
                                            </a>
                                            <div className="eventTime">
                                                {event.eventStartTime} - {event.eventEndTime}
                                            </div>
                                        </li>
                                    </CSSTransition>
                                );
                            })}
                            {eventsLater.length === 0 && (
                                <CSSTransition
                                    classNames="noevent"
                                    timeout={timeoutDuration}
                                    onEntered={updateVisibility}
                                    onExited={updateVisibility}
                                >
                                    <li className={`no-events ${getFadeClassName('noEventsLater')}`} ref={noEventsLaterRef}>
                                        No events happening later today.
                                    </li>
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                </div>

                <h2 className={titleFadeClass}>Upcoming Events</h2>
                {Object.entries(upcoming).map(([formattedDate, eventsForDate]) => {
                    upcomingEventRefs.current[formattedDate] = upcomingEventRefs.current[formattedDate] || React.createRef();
                    return (

                        <div key={formattedDate} ref={upcomingEventRefs.current[formattedDate]}
                            className={getFadeClassName(formattedDate)}>
                            <div className="eventDateGroup">{formattedDate} </div>
                            <div className="afterSpacer">
                                {eventsForDate.length === 0 ? (
                                    <li className="noupcoming-events">No events on this day.</li>
                                ) : (
                                    <TransitionGroup>
                                        {eventsForDate.map((event) => {
                                            return (
                                                <CSSTransition
                                                    key={event.id}
                                                    classNames="upcomingevent"
                                                    timeout={3000}
                                                    onEntered={updateVisibility}
                                                    onExited={updateVisibility}>
                                                    <li key={event.id} className="groupedEventContainer">
                                                        <a href={`https://www.google.com/search?q=${encodeURIComponent(event.eventName + ' Islamic Center of Perris')}`} target="_blank" rel="noopener noreferrer" className="eventDetailsLink">
                                                            <div className="eventDetails">
                                                                <strong>{event.eventName}</strong> - {event.eventRecurrence} - {event.eventLocation}<br />
                                                            </div>
                                                        </a>
                                                        <div className="eventTime">
                                                            {event.eventStartTime.replace(/ /g, '\u00a0')} - {event.eventEndTime.replace(/ /g, '\u00a0')}
                                                        </div>
                                                    </li>
                                                </CSSTransition>
                                            );
                                        })}
                                    </TransitionGroup>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Footer />
        </div>
    );
}

export default EventsDisplay;