import React, { useState, useEffect } from 'react';

function Clock() {
    const [time, setTime] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();

            // Manually construct the date part
            const weekday = now.toLocaleString('en-US', { weekday: 'long', timeZone: 'America/Los_Angeles' });
            const month = now.toLocaleString('en-US', { month: 'short', timeZone: 'America/Los_Angeles' });
            const day = now.toLocaleString('en-US', { day: 'numeric', timeZone: 'America/Los_Angeles' });
            const year = now.toLocaleString('en-US', { year: 'numeric', timeZone: 'America/Los_Angeles' });
            const datePart = `${weekday} ${month} ${day}, ${year}`;

            // Format the time part
            const timeOptions = { hour: 'numeric', minute: '2-digit' };
            const timePart = new Intl.DateTimeFormat('en-US', { ...timeOptions, timeZone: 'America/Los_Angeles' }).format(now);

            // Combine date and time with non-breaking spaces
            const newYorkDateTime = `${datePart}&nbsp;&nbsp;&nbsp;${timePart}`;
            setTime(newYorkDateTime);
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return <div className="clockStyle" dangerouslySetInnerHTML={{ __html: time }}></div>;
}

export default Clock;
